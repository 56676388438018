import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { addDays, format, set } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./searchBar.css";
import {
	Button,
	Divider,
	Grid,
	Modal,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LocalBookingForm from "../localBookingForm";

const SearchBar = ({ handleClose }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [to, setTo] = useState(searchParams.get("to") ?? "");
	const [toInput, setToInput] = useState(searchParams.get("to") ?? "");

	const [distance, setDistance] = useState(searchParams.get("distance") ?? "");

	const [suggestList, setSuggestList] = useState([]);
	const [selectionRange, setSelectionRange] = useState({
		startDate: searchParams.get("startDate") ?? new Date(),
		endDate: addDays(new Date(), 7),
		key: "selection",
	});
	const [pickupTime, setPickupTime] = useState(
		searchParams.get("pickupTime") ?? "10:00"
	);

	const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
	const [type, setType] = React.useState(
		searchParams.get("type") ?? "outstation"
	);

	const [localBooking, setLocalBooking] = useState(false);

	function debounce(func, timeout = 300) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}

	const handleSelect = (ranges) => {
		setSelectionRange(ranges.selection);
	};

	const handleSearch = () => {
		if (!to && !distance) return;

		let params = new URLSearchParams({
			from: "Bangalore",
			to,
			startDate: selectionRange.startDate,
			endDate: selectionRange.endDate,
			pickupTime,
			type,
			distance,
		});

		navigate("/fleet?" + params.toString());
	};

	const handleToDestination = async (value) => {
		if (!value) {
			setSuggestList([]);
			return;
		}
		try {
			const { suggestions } = await fetch(
				"https://places.googleapis.com/v1/places:autocomplete",
				{
					headers: {
						"Content-Type": "application/json",
						"X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_MAPS_API,
					},
					method: "POST",
					body: JSON.stringify({
						input: value,
						includedRegionCodes: ["in"],
						origin: { latitude: 12.971599, longitude: 77.594566 },
					}),
				}
			)
				.then((res) => res.json())
				.then((data) => {
					return data;
				})
				.catch((err) => {
					console.log("error", err);
				});

			if (!suggestions) return;
			setSuggestList(
				suggestions.map((suggestion) => ({
					text: suggestion.placePrediction.text.text,
					distance: suggestion.placePrediction.distanceMeters,
				}))
			);

			// const res = await fetch(
			// 	`https://api.olamaps.io/places/v1/autocomplete?input=${value}&origin=12.971599,77.594566&api_key=${process.env.REACT_APP_OLA_MAPS_API}`
			// )
			// 	.then((res) => res.json())
			// 	.then((data) => data);

			// if (res.status === "ok") {
			// 	setSuggestList(
			// 		res.predictions.map((prediction) => ({
			// 			text: prediction.description,
			// 			distance: prediction.distance_meters,
			// 		}))
			// 	);
			// }
		} catch (error) {
			console.log(error);
		}
	};

	const handleFormClose = () => {
		setType("outstation");
		setLocalBooking(false);
	};

	// useEffect(() => {
	// 	if (type === "airport") {
	// 		setTo("Kempegowda International Airport, Bangalore (BLR)");
	// 		setToInput("Kempegowda International Airport, Bangalore (BLR)");
	// 		setDistance(28051);
	// 	}
	// }, [type]);

	let innerWidth = window.innerWidth;
	return (
		<>
			<Modal
				open={localBooking}
				onClose={handleFormClose}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100%",
					}}
				>
					<LocalBookingForm handleFormClose={handleFormClose} />
				</div>
			</Modal>
			<Grid
				container
				padding={2}
			>
				<Modal
					open={isDateRangePickerOpen}
					onClose={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Grid
						borderRadius={2}
						sx={{
							overflow: "hidden",
						}}
					>
						<DateRangePicker
							ranges={[selectionRange]}
							onChange={handleSelect}
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							retainEndDateOnFirstSelection={true}
							rangeColors={["#dd3751"]}
							months={innerWidth > 768 ? 2 : 1}
							direction={innerWidth > 768 ? "horizontal" : "vertical"}
							showMonthArrow
							showMonthAndYearPickers={true}
							dragSelectionEnabled={true}
							staticRanges={[]}
							inputRanges={[]}
						/>
					</Grid>
				</Modal>
				<Grid
					container
					alignItems="center"
					paddingTop={10}
					paddingBottom={6}
					paddingInline={2}
					borderRadius={2}
					xs={12}
					sx={{
						backgroundColor: "white",
						position: "relative",
					}}
				>
					{handleClose && (
						<div style={{ position: "absolute", top: 10, right: 10 }}>
							<CloseRoundedIcon
								onClick={handleClose}
								sx={{ cursor: "pointer" }}
							/>
						</div>
					)}
					<Grid
						container
						item
						rowGap={3}
						columnSpacing={2}
						lg={11}
					>
						<Grid
							item
							xs={12}
							lg={3}
							md={6}
						>
							<Typography
								variant="h6"
								color={"slategray"}
							>
								From
							</Typography>
							<TextField
								readOnly
								variant="standard"
								value={"Bangalore"}
								placeholder="Enter departure city"
								fullWidth
								size={"medium"}
								type="text"
							/>
						</Grid>
						<Grid
							item
							xs={12}
							lg={3}
							md={6}
						>
							<Typography
								variant="h6"
								color={"slategray"}
							>
								To
							</Typography>
							<Grid
								item
								sx={{ position: "relative" }}
							>
								<TextField
									readOnly={type === "airport"}
									variant="standard"
									value={toInput}
									placeholder="Enter destination city"
									onChange={(event) => {
										setToInput(event.target.value);
										debounce(handleToDestination(event.target.value));
									}}
									fullWidth
									size={"medium"}
									type="text"
									required
									error={!to}
								/>

								{!!suggestList.length && toInput && (
									<Grid
										container
										border={1}
										borderRadius={1}
										borderColor={"#e0e0e0"}
										sx={{
											position: "absolute",
											top: 32,
											left: 0,
											right: 0,
											zIndex: 999999,
											overflowY: "scroll",
											backgroundColor: "white",
											maxHeight: 200,
											boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
										}}
									>
										{suggestList.map((suggestion, index) => (
											<Grid
												item
												key={suggestion.text}
												onClick={() => {
													setTo(suggestion.text);
													setToInput(suggestion.text);
													setDistance(suggestion.distance);
													setSuggestList([]);
												}}
												sx={{
													width: "100%",
												}}
												className={"dropdownItem"}
											>
												<Typography
													variant="body1"
													paddingBlock={0.8}
													paddingInline={1}
												>
													{suggestion.text}
												</Typography>
												<Divider fullWidth />
											</Grid>
										))}
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							lg={2}
							md={6}
						>
							<Typography
								variant="h6"
								color={"slategray"}
							>
								Departure
							</Typography>
							<TextField
								readOnly
								variant="standard"
								value={`${format(selectionRange.startDate, "dd MMM yyyy")}`}
								onClick={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
								fullWidth
								size={"medium"}
								type="text"
								required
							/>
						</Grid>
						<Grid
							item
							xs={12}
							lg={2}
							md={6}
						>
							<Typography
								variant="h6"
								color={"slategray"}
							>
								Return
							</Typography>
							<TextField
								readOnly
								variant="standard"
								value={`${format(selectionRange.endDate, "dd MMM yyyy")}`}
								onClick={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)}
								fullWidth
								size={"medium"}
								type="text"
								required
							/>
						</Grid>
						<Grid
							item
							xs={12}
							lg={2}
							md={6}
						>
							<Typography
								variant="h6"
								color={"slategray"}
							>
								Pickup Time
							</Typography>
							<TextField
								readOnly
								variant="standard"
								value={pickupTime}
								onChange={(e) => setPickupTime(e.target.value)}
								fullWidth
								size={"medium"}
								type="time"
								required
							/>
						</Grid>
					</Grid>
					<Grid
						item
						container
						xs={12}
						lg={1}
						paddingTop={3}
						alignItems={"center"}
						justifyContent={"center"}
						paddingLeft={2}
					>
						<Button
							size="large"
							variant="contained"
							sx={{
								backgroundColor: "#dd3751",
								"&:hover": {
									backgroundColor: "#dd3751",
								},
							}}
							onClick={handleSearch}
						>
							Search
						</Button>
					</Grid>

					<Grid
						container
						alignItems={"center"}
						justifyContent={"center"}
					>
						<ToggleButtonGroup
							color="primary"
							value={type}
							exclusive
							sx={{
								top: 10,
								position: "absolute",
								backgroundColor: "white",
							}}
						>
							<ToggleButton
								value="outstation"
								defaultChecked
								onClick={() => {
									setType("outstation");
								}}
							>
								Outstation
							</ToggleButton>
							<ToggleButton
								value="local"
								onClick={() => {
									setType("local");
									setLocalBooking(true);
								}}
							>
								Local
							</ToggleButton>
							<ToggleButton
								value="airport"
								onClick={() => {
									setType("airport");
									setLocalBooking(true);
								}}
							>
								Airport
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default SearchBar;
