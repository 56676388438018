/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Layout from "../../layout";
import { json, useNavigate } from "react-router";
import accordianDataJSON from "./faq.json";
const styles = {
	container: {
		maxWidth: "1200px",
		width: "90vw",
		margin: "40px auto",
		padding: "0 20px",
	},
	title: {
		textAlign: "center",
		color: "#333",
		marginBottom: "40px",
		fontSize: "2.5em",
	},
	accordion: {
		background: "#fff",
		borderRadius: "8px",
		boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
	},
	item: {
		borderBottom: "1px solid #eee",
	},
	lastItem: {
		borderBottom: "none",
	},
	button: {
		width: "100%",
		padding: "20px",
		background: "none",
		border: "none",
		textAlign: "left",
		cursor: "pointer",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		position: "relative",
		transition: "background-color 0.3s ease",
	},
	buttonHover: {
		backgroundColor: "#f8f8f8",
	},
	question: {
		fontSize: "26px",
		color: "#333",
		fontWeight: 500,
		flexGrow: 1,
		marginRight: "40px",
	},
	arrow: (isActive) => ({
		width: "10px",
		height: "10px",
		borderRight: "2px solid #666",
		borderBottom: "2px solid #666",
		transform: isActive ? "rotate(-135deg)" : "rotate(45deg)",
		transition: "transform 0.3s ease",
	}),
	content: (isActive) => ({
		maxHeight: isActive ? "500px" : "0",
		overflow: "hidden",
		transition: "max-height 0.3s ease-out",
	}),
	answer: {
		padding: "0 20px 20px",
		color: "#666",
		lineHeight: 1.6,
		fontSize: "22px",
	},
};

function FAQ() {
	let navigate = useNavigate();
	const [activeIndex, setActiveIndex] = useState(null);
	const handleNavigateHome = () => {
		navigate("/");
	};

	const faqData = accordianDataJSON;

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<Layout>
			<div className="tj-breadcrumb">
				<div className="container">
					<ul className="breadcrumb-list">
						<li>
							<a onClick={handleNavigateHome}>Home</a>
						</li>
						<li className="active">FAQ</li>
					</ul>
				</div>
			</div>
			<div style={styles.container}>
				<h1 style={styles.title}>Frequently Asked Questions</h1>
				<div style={styles.accordion}>
					{faqData.faq.map((item, index) => (
						<div
							key={index}
							style={{
								...styles.item,
								...(index === faqData.faq.length - 1 ? styles.lastItem : {}),
							}}
						>
							<button
								style={styles.button}
								onClick={() => toggleAccordion(index)}
								onMouseEnter={(e) => {
									e.currentTarget.style.backgroundColor = "#f8f8f8";
								}}
								onMouseLeave={(e) => {
									e.currentTarget.style.backgroundColor = "transparent";
								}}
							>
								<span style={styles.question}>{item.question}</span>
								<span style={styles.arrow(activeIndex === index)} />
							</button>
							<div style={styles.content(activeIndex === index)}>
								<div style={styles.answer}>{item.answer}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</Layout>
	);
}

export default React.memo(FAQ);
